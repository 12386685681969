(function () {
'use strict';

angular.module('hyresgastApp').directive('hyArrayDisplay', hyArrayDisplay);

function hyArrayDisplay($sce) {
  var directive = {
    restrict: 'E',
    templateUrl: 'components/array-display/array-display.html',
    scope: {
      model: '=',
    },
    // eslint-disable-next-line no-unused-vars
    link: (scope, element, attrs) => {
      const maxRows = 18;
      let split = newValue => {
        if (newValue instanceof Array) {
          scope.first = newValue[0];
          scope.columnCount = Math.min(5, Math.ceil(newValue.length / maxRows));
          scope.rest = $sce.trustAsHtml(newValue.length > 1
            ? `<div style='column-count: ${scope.columnCount}'>
                ${newValue.slice(1).join('<br/>')}
              </div>`
            : '');
        } else {
          scope.first = newValue;
          scope.rest = '';
        }
      };
      //split(newValue);
      scope.$watch('model', newValue => {
        if (newValue) {
          split(newValue);
        }
      });
    },
  };
  return directive;


}
})();