(function () {
'use strict';

angular.module('hyresgastApp').directive('hyTableau', hyTableau);

const fAllId = -99999;

/**
 * @typedef TableauColumn
 * @property {string} title
 * @property {string} column
 * @property {number} type
 */

function hyTableau($location, $timeout, HelperService) {
  return {
    restrict: 'E',
    templateUrl: 'components/tableau/tableau.html',
    scope: {
      columns: '=',
      filtersOverride: '=',
      defaultSorting: '@',

      inMemory: '=',
      inMemoryExcessiveLimit: '=',
      checkboxes: '=',
      checkboxesAlternative: '=',
      selectedRows: '=',

      fGetData: '&',
      fPostprocessData: '&',

      limit: '=',
      noSorting: '=',
      noFiltering: '=',
      noPagination: '=',

      fRowClick: '&',
      useUrl: '=',

      idProperty: '@',
      seenbyWarning: '=',

      actions: '=',
    },
    // eslint-disable-next-line no-unused-vars
    link: (scope, element, attrs) => {

      scope.timed = f => $timeout(f, 200);
      //const getTicked = ticked => Object.keys(ticked).filter(k => ticked[k]).map(id => parseInt(id));
      const reductor = (acc, el) => { acc[el[scope.idProperty]] = true; return acc; };
      const tickedOptionAll = 'Alla';
      const tickedOptionChecked = 'Vald';
      const tickedOptionUnchecked = 'Avvald';

      scope.ready = false;
      scope.offset = 0;
      scope.total = 0;

      let initSorting = () => {
        if (scope.useUrl) {
          scope.sortColumn = $location.search().order;
          scope.sortDirection = $location.search().direction;
        }
        if (!scope.sortColumn && scope.defaultSorting) {
          let tokens = scope.defaultSorting.split(' ');
          scope.sortColumn = tokens[0];
          scope.sortDirection = tokens[1];
        }
        if (!scope.sortColumn) {
          scope.sortColumn = scope.sortColumns[0].column;
        }
        if (!scope.sortDirection) {
          scope.sortDirection = 'ASC';
        }
      };
      let initSortingColumns = () => {
        scope.sortColumns = scope.columns.filter(column => {
          let nonFilter = column.filterType === 'none';
          let isArray = column.type === 'array';
          return !nonFilter && !isArray;
        });
      };
      let initFilters = () => {
        if (!scope.useUrl) return {};
        return scope.columns.filter(x => x.filterType !== 'none').reduce((acc, el) => {
          let value = $location.search()[el.column];
          acc[el.column] = el.lookup
            // eslint-disable-next-line eqeqeq
            ? el.lookup.dictionary.find(x => x[el.lookup.idProperty] == value)
            : value;
          return acc;
        }, {});
      };
      let initColumns = () => {
        scope.columns.forEach(column => {
          if (column.lookup) {
            let fAll = {  };
            fAll[column.lookup.idProperty] = fAllId;
            fAll[column.lookup.textProperty] = 'Alla';
            column.lookup.dictionary = [fAll].concat(column.lookup.dictionary);
          }
        });
      }
      scope.initReady = false;
      let maybeInit = () => {
        if (!(scope.fGetData && scope.fGetData()) || !scope.columns || scope.initReady) return;
        if (scope.inMemory && !scope.inMemoryExcessiveLimit) return;
        initColumns();
        initSortingColumns();
        initSorting();
        scope.offset = 0;
        scope.filters = scope.filtersOverride ? scope.filtersOverride : initFilters();
        scope.initReady = true;
        getData();
      };

      // eslint-disable-next-line no-unused-vars
      scope.$watch('columns', newData => maybeInit());
      // eslint-disable-next-line no-unused-vars
      scope.$watch('fGetData', (newFn, oldFn) => maybeInit());
      // eslint-disable-next-line no-unused-vars
      scope.$watchGroup(['inMemory', 'inMemoryExcessiveLimit'], newData => maybeInit());

      let sortingOrFilteringChanged = () => {
        if (!scope.inMemory) {
          getData(true);
        } else {
          scope.applyFilter();
        }
      };
      if (scope.useUrl) {
        scope.$on('$routeUpdate', sortingOrFilteringChanged);
      } else {
        scope.$watch('sortColumn', () => { scope.timed(sortingOrFilteringChanged) });
        scope.$watch('sortDirection', () => { scope.timed(sortingOrFilteringChanged) });
      }
      scope.applyTickedFilterDropdown = () => {
        scope.offset = 0;
        scope.filters = { ticked: scope.filters.ticked };
        scope.applyTickedFilter();
      };
      scope.applyTickedFilter = () => {
        $timeout(() => scope.applyFilter('ticked'), 200);
      };
      let setSelectedRows = () => {
        scope.selectedRows = scope.data.filter(x => scope.ticked[x[scope.idProperty]] === true);
      }
      scope.toggle = all => {
        let array = all ? scope.data : scope.filteredData;

        let arrayTicked = array.filter(x => scope.ticked[x[scope.idProperty]]);
        let newValue = arrayTicked.length < array.length;
        array.forEach(x => scope.ticked[x[scope.idProperty]] = newValue);
        setSelectedRows();

        scope.applyTickedFilter();
      };
      scope.isFiltered = () => {
        if (scope.filteredData) {
          return scope.filteredData.length < scope.data.length;
        } else {
          return false;
        }
      };
      scope.getFilteredTickedTotal = () => {
        if (scope.filteredData) {
          return scope.filteredData.filter(x => scope.ticked[x[scope.idProperty]]).length;
        }
      };
      scope.getFilteredStateTitle = all => {
        if (!scope.checkboxes) {
          return '';
        }
        return all
          ? scope.selectedRows && (scope.selectedRows.length < scope.total)
          : (scope.getFilteredTickedTotal() < scope.getTotal()) ? 'Markera' : 'Avmarkera';
      };
      scope.getTotal = () => {
        if (scope.inMemory && scope.filteredData) {
          return scope.filteredData.length;
        } else {
          return scope.total;
        }
      };
      scope.applyFilterTimed = columnKey => $timeout(() => scope.applyFilter(columnKey), 100);
      scope.applyFilter = columnKey => {
        if (scope.inMemory) {
          let filterKeys = Object.keys(scope.filters).filter(k => scope.filters[k]);
          scope.filteredData = scope.data.filter(x => {
            let pass = true;
            filterKeys.filter(x => x !== 'ticked').forEach(k => {
              let type = scope.columns.find(c => c.column === k).filterType;
              let value = scope.filters[k];
              if (!x[k]) {
                pass = false;
                return;
              }
              if (type !== 'none' && value) {
                pass &= x[k].toLowerCase().includes(value.toLowerCase());
              }
            });
            if (scope.checkboxes) {
              let checkedOnly = scope.filters.ticked === tickedOptionChecked;
              let uncheckedOnly = scope.filters.ticked === tickedOptionUnchecked;
              let isTicked = scope.ticked[x[scope.idProperty]];
              if (checkedOnly) {
                pass &= isTicked;
              }
              if (uncheckedOnly) {
                pass &= !isTicked;
              }
            }
            return pass;
          });
          scope.filteredData = scope.filteredData.sort(HelperService.dynamicSortSwe(scope.sortColumn, scope.sortDirection));
          scope.setDataVM();
          if (scope.checkboxes) {
            setSelectedRows();
          }
        } else {
          if (scope.useUrl) {
            let newData = {};
            newData[columnKey] = scope.filters[columnKey];
            let column = scope.columns.find(c => c.column === columnKey);
            if (column.lookup) {
              newData[columnKey] = newData[columnKey][column.lookup.idProperty];
            }
            $location.search(angular.extend($location.search(), newData));
          } else {
            sortingOrFilteringChanged();
          }
        }
      };
      scope.flipPage = function () {
        if (scope.inMemory) {
          scope.setDataVM();
        } else {
          getData();
        }
      };
      // eslint-disable-next-line no-unused-vars
      scope.$on("refresh", function (event, data) {
        scope.filteredData = null;
        if (data) {
          scope.filters = data;
        } else {
          scope.filters = {};
        }
        getData(true);
      });

      scope.setDataVM = function () {
        if (scope.inMemory) {
          let data = scope.filteredData ? scope.filteredData : scope.data;
          scope.dataVM = data.slice(scope.offset, scope.offset + scope.limit);
        } else {
          scope.dataVM = scope.data;
        }
      };

      let getData = filterMode => {
        if (!scope.initReady) {
          return;
        }

        if (filterMode) {
          scope.offset = 0;
        }

        let params = {
          limit: scope.inMemory ? scope.inMemoryExcessiveLimit : scope.limit,
          offset: scope.inMemory ? 0 : scope.offset,
          direction: scope.sortDirection,
          order: scope.sortColumn,
        };

        let filters = scope.columns.reduce((acc, column) => {
          let key = column.column;
          if (scope.filters[key]) {
            if (column.lookup) {
              let value = scope.filters[key][column.lookup.idProperty];
              if (value !== fAllId) {
                acc[`filters[${key}]`] = value;
              }
            } else {
              acc[`search[${key}]`] = scope.filters[key];
            }
          }
          return acc;
        }, {});

        Object.assign(params, filters);

        scope.ready = false;
        let tuAsPromis = scope.fGetData()(params);
        if (tuAsPromis.$promise) {
          tuAsPromis = tuAsPromis.$promise;
        }
        tuAsPromis.then(response => {
          scope.ready = true;
          scope.data = response.rows;
          scope.total = response.count;
          if (scope.checkboxes && scope.idProperty) {
            if (scope.checkboxesAlternative) {
              scope.ticked = {};
              scope.selectedRows = [];
            } else {
              scope.ticked = scope.data.reduce(reductor, {});
              scope.selectedRows = scope.data;
            }
            scope.tickedFilterItems = [tickedOptionAll, tickedOptionChecked, tickedOptionUnchecked];
            scope.filters.ticked = tickedOptionAll;
          }
          scope.setDataVM();
          if (scope.fPostprocessData && scope.fPostprocessData()) {
            scope.fPostprocessData()(scope.data);
          }
          scope.timed(() => scope.$apply());
        });
      };

      scope.getFilterType = column => {
        if (column.filterType) {
          if (column.filterType === 'none') return null;
          return 'text';
        }
        if (!column.type) {
          return column.lookup ? 'combobox' : 'text';
        }
        if (['array'].includes(column.type)) return 'text';
        if (['star'].includes(column.type)) return null;
      };
      scope.getLookupValue = (item, column) => {
        let value = column.lookup.dictionary.find(x => x[column.lookup.idProperty] === item[column.column]);
        if (value) {
          return value[column.lookup.textProperty];
        }
        return null;
      };
      scope.itemSelected = item => {
        if (scope.fRowClick && scope.fRowClick()) {
          scope.fRowClick()(item);
        }
      };
      scope.doAction = action => { action.action() };
    }
  }
}
})();